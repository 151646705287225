.content-center h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0px;
}

table.table-lists td {
  font-weight: 400 !important;
  padding: 0px 15px 0px 15px !important;
  height: 42px;
  vertical-align: middle;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.box-list-header {
  display: inline-flex;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.box-list-header > .box-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
}

.box-input-text {
  width: 63%;
  margin-right: 10px;
}

.input-cs > .input-group-text,
.input-cs input {
  background: #f5f5f5;
  border: 0px;
}

.input-cs > .input-group-text {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  padding: 0px 15px;
  font-size: 18px;
  color: #828282;
}

.input-cs input {
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  padding: 8px;
}

.input-cs > .input-group-text {
  border: 0px;
}

.input-cs input::placeholder {
  color: #828282 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-cs input:focus {
  background: #f5f5f5 !important;
  border: none !important;
  outline: 0;
  box-shadow: unset !important;
}

.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.input-password > .input-group-text {
  background-color: #fff !important;
  cursor: pointer;
}

.bt-plus-circle,
.bt-filter-circle,
.bt-filter-circle-selected,
.bt-download-circle {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bt-filter-circle {
  background-image: url('../../../public/Images/icon-filter-default.png');
}
.bt-filter-circle-selected {
  background-image: url('../../../public/Images/icon-filter-hover.png');
}

.bt-filter-circle:hover {
  background-image: url('../../../public/Images/icon-filter-hover.png');
}

.bt-plus-circle {
  background-image: url('../../../public/Images/icon-plus-default.png');
}

.bt-plus-circle:hover {
  background-image: url('../../../public/Images/icon-plus-hover.png');
}

.box-table {
  max-height: 335px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box-table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

table.table-lists {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  vertical-align: middle;
}

table.table-lists th {
  background-color: #fff;
  border-left: 0px;
  text-align: center;
  color: #828282;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  letter-spacing: -0.02em;
  border-bottom: solid 1px #eee;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-lists thead th:first-child {
  border-left: 1px solid transparent;
  width: 55%;
  text-align: left;
}

table.table-lists tbody tr td:first-child {
  border-top-left-radius: 12px !important;
  border-left: 1px solid transparent;
  border-bottom-left-radius: 12px !important;
  text-align: left;
  color: #3c3c3c;
}

table.table-lists tbody tr td:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

table.table-lists td {
  background: #f5f5f5;
  border-left: 0px;
  text-align: center;
  color: #000;
  vertical-align: middle;
  font-size: 16px;
}

table.table-lists td i {
  font-weight: bold;
}

table.table-lists tr.bg-yellow td,
table.table-lists tr:hover td {
  background-color: rgba(255, 228, 38, 0.4) !important;
}

table.table-lists tr.bg-yellow td > span.bt-circle-wh,
span.bt-circle-wh:hover {
  background-color: white;
  display: inline-flex;
  width: 32px;
  height: 32px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

span.bt-circle-wh {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 2.5px;
}

span.bt-circle-wh img {
  height: 26px;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.bt-icon-attr {
  background-image: url('../../../public/Images/icon-non-attr.png');
}

.bt-icon-en-cour {
  background-image: url('../../../public/Images/icon-en-cour.png');
}

.bt-icon-en-retard {
  background-image: url('../../../public/Images/icon-en-retard.png');
}

.bt-icon-valid {
  background-image: url('../../../public/Images/icon-valid.png');
}

.bt-icon-smart-with-review {
  background-image: url('../../../public/Images/icon-smart-with-review.png');
}

.bt-icon-smart {
  background-image: url('../../../public/Images/icon-smart.png');
}

.bt-icon-list {
  position: relative;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  background-size: contain;
}

.bg-orange {
  background-color: #ff8d4b;
}

.bg-gris {
  background-color: #868b8e;
}

.bg-blue {
  background-color: #0064d2;
}

.bg-darkorange {
  background-color: #ee3900;
}

.bg-green {
  background-color: #43b929;
}

.box-circle {
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}

.box-header-icon {
  width: 32px;
  height: 38px;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.label-text {
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3c3c3c;
  line-height: 25px;
  margin: 15px 0px;
  font-weight: 500;
}

.box-pd {
  padding: 16.5px 10px;
  cursor: pointer;
}

.box-pd:hover > .label-text,
.box-pd:hover span i {
  color: #fff;
}

.box-pd:hover span i {
  font-size: 15px !important;
}

.box-pd:hover > .box-header-icon > .bt-icon-list > .icon-sm i {
  font-size: 32px !important;
}

/*box orange*/

.box-pd-orange:hover span {
  background-color: #ffb185 !important;
}

.box-pd-orange:hover {
  background-color: #ffb185;
  color: #fff;
}

.box-pd-gris:hover span {
  background-color: #868b8e !important;
}

.box-pd-gris:hover {
  background-color: #868b8e;
  color: #fff;
}
.box-pd:hover > .box-circle.bg-orange {
  background-color: #fff !important;
  color: #ff8d4b;
}

.box-pd:hover > .box-circle.bg-gris {
  background-color: #fff !important;
  color: #868b8e;
}

/*box blue*/

.box-pd-blue:hover span {
  background-color: #7fb1e8 !important;
}

.box-pd-blue:hover {
  background-color: #7fb1e8;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-blue {
  background-color: #fff !important;
  color: #0064d2;
}

/*box dark orange*/

.box-pd-darkorange:hover span {
  background-color: #f79f83 !important;
}

.box-pd-darkorange:hover {
  background-color: #f79f83;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-darkorange {
  background-color: #fff !important;
  color: #ee3900;
}

/*box green*/

.box-pd-green:hover span {
  background-color: #a1dc94 !important;
}

.box-pd-green:hover {
  background-color: #a1dc94;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-green {
  background-color: #fff !important;
  color: #43b929;
}

.box-pd-blue:hover > .box-header-icon > .bt-icon-en-cour {
  background-image: url('../../../public/Images/icon-en-cour-white.png');
  transform: scale(1.2);
}

.box-pd-orange:hover > .box-header-icon > .bt-icon-attr {
  background-image: url('../../../public/Images/icon-none-attr-white.png');
  transform: scale(1.2);
}
.box-pd-gris:hover > .box-header-icon > .bt-icon-attr {
  background-image: url('../../../public/Images/icon-none-attr-white.png');
  transform: scale(1.2);
}

.box-pd-darkorange:hover > .box-header-icon > .bt-icon-en-retard {
  background-image: url('../../../public/Images/icon-en-retard-white.png');
  transform: scale(1.2);
}

.box-pd-green:hover > .box-header-icon > .bt-icon-valid {
  background-image: url('../../../public/Images/icon-valid-white.png');
  transform: scale(1.2);
}
