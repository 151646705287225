body {
  font-size: 14px;
  font-weight: 400;
  padding-top: 100px;
  zoom: 0.75;
  color: #3c3c3c;
  font-family: 'Poppins', sans-serif !important;
}

.wrapper-content {
  padding: 40px 50px 50px 150px;
  width: 100%;
}
.wrapper-content-c {
  padding: 40px 50px 50px 50px;
  width: 100%;
}

.scrollable-element {
  scrollbar-color: red yellow !important;
}

.text-note {
  color: #828282;
  font-size: 12px;
  margin: 5px 0px 0px 10px;
  font-weight: 300;
  letter-spacing: -0.24px;
}

.bg-1 {
  width: 132px;
  height: 132px;
  top: 150px;
  right: 424px;
  background-image: url('../public/Images/img-bg/c1.png');
}

.bg-2 {
  width: 290px;
  height: 430px;
  top: 265px;
  right: 0px;
  background-image: url('../public/Images/img-bg/c2.png');
}

.bg-3,
.bg-9 {
  width: 169px;
  height: 169px;
  bottom: -17px;
  left: 300px;
  background-image: url('../public/Images/img-bg/c3.png');
}

.bg-4 {
  position: absolute;
  width: 328px;
  height: 265px;
  bottom: -71px;
  right: -38px;
  background-image: url('../public/Images/img-bg/c4.png');
}

.bg-5,
.bg-10 {
  position: absolute;
  width: 173px;
  height: 173px;
  bottom: 220px;
  right: 290px;
  background-image: url('../public/Images/img-bg/c5.png');
}

.bg-6,
.bg-8 {
  position: absolute;
  width: 94px;
  height: 94px;
  top: 0px;
  right: 105px;
  background-image: url('../public/Images/img-bg/c6.png');
}

.bg-7 {
  position: absolute;
  width: 303px;
  height: 303px;
  top: 195px;
  right: -173px;
  background-image: url('../public/Images/img-bg/c7.png');
}

.bg-7-1 {
  position: absolute;
  width: 303px;
  height: 303px;
  top: 195px;
  right: -300px;
  background-image: url('../public/Images/img-bg/c7.png');
}

.bg-8 {
  position: absolute;
  width: 94px;
  height: 94px;
  top: -9px;
  right: -8px;
  background-image: url('../public/Images/img-bg/c6.png');
}

.bg-9 {
  width: 185px;
  height: 185px;
  bottom: -5px;
  left: 228px;
  background-image: url('../public/Images/img-bg/c9.png');
}
.label-choice {
  padding-top: 10px;
}

.bg-10 {
  bottom: 140px;
  right: 365px;
}

.bg-11 {
  position: absolute;
  width: 377px;
  height: 271px;
  bottom: -71px;
  right: -38px;
  background-image: url('../public/Images/img-bg/c10.png');
}

.bg-12 {
  position: absolute;
  width: 403px;
  height: 265px;
  bottom: -71px;
  right: 307px;
  background-image: url('../public/Images/img-bg/c11.png');
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: transparent;
}

@media screen and (max-width: 768px) {
  .wrapper-content {
    padding: 40px 20px 50px 120px !important;
    width: 100%;
  }
}

.theme-container {
  background-color: #ffe426;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  align-items: center;
  margin-bottom: 5px;
}

.theme-container h5 {
  margin-right: 5px;
}

.theme-container button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.bt-submit-y {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
}

.bt-submit-v {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
}
.bt-submit-v-c {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  padding: 0px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
}
.bt-submit-v-c-disabled {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  padding: 0px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
  cursor: pointer;
  background-color: #e0e0e0;
}

.box-wrapper-gray {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 15px;
}

.box-txt {
  display: flex;
  font-size: 16px;
  color: #3c3c3c;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #bdbdbd;
  margin-bottom: 15px;
  align-items: center;
}

.box-txt div:first-child {
  flex: 1;
}

.box-txt strong {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: left;
}

.i-13 {
  font-size: 13px;
}

.label-text-h {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 15px;
}

.label-text-h strong {
  font-weight: 500;
}

.box-list-text {
  height: 284px;
  overflow: auto;
  position: relative;
}

.box-list-text ul,
.tab-link-editor ul,
.tab-icon-editor ul {
  margin: 0px;
  padding: 0px;
}

.box-list-text li,
.tab-link-editor li,
.tab-icon-editor li {
  list-style: none;
}

.box-list-text li,
.sub-header-title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

.box-motcle {
  height: 222px;
}

.txt-gray {
  color: #ccc;
}

.box-graph {
  margin: 15px 0px;
}

.box-wrapper-editor {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.tab-link-editor {
  padding: 0px 10px;
  border-bottom: 1px solid #bdbdbd;
}

.tab-link-editor li {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}

.tab-icon-editor {
  display: flex;
  font-size: 12px;
  padding: 0px 5px;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #bdbdbd;
}

.tab-icon-editor > .box-list-icon:last-child {
  border-right: 0px;
}

.box-list-icon {
  border-right: solid 1px #ddd;
  padding: 0px 5px;
}

.box-list-icon > .dropdown > .dropdown-custom,
.box-list-icon > .bx-icon > .dropdown > .dropdown-custom {
  font-size: 13px;
  border: 0px !important;
  height: 34px !important;
  padding: 0px !important;
}

.box-list-icon > .dropdown > .dropdown-custom span,
.box-list-icon > .bx-icon > .dropdown > .dropdown-custom span {
  right: 0px !important;
}

.box-list-icon > .bx-icon {
  display: inline-flex;
  font-size: 14px;
  padding: 0px 5px;
  height: 35px !important;
  align-items: center;
  justify-content: center;
}

.label-box {
  display: inline-flex;
  background-color: #c8cace;
  padding: 5px 8px;
  border-radius: 3px;
  height: 28px;
  align-items: center;
}

.box-textarea {
  padding: 10px;
  position: relative;
  height: 430px;
}

.box-textarea textarea {
  resize: none;
  font-weight: 400;
  font-size: 16px;
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  height: 400px;
}

.box-textarea > .form-control:focus {
  box-shadow: none !important;
  border: 0px !important;
  text-align: left;
}

.box-botom-flex {
  display: flex;
  margin-top: 15px;
  font-size: 16px;
}

.box-botom-flex div:first-child {
  flex: 1;
}

.box-list-dropdown {
  display: inline-flex;
  margin-top: 10px;
  align-items: center;
}

.box-detail-text {
  margin-top: 15px;
  font-size: 16px;
  margin-bottom: 15px;
}

.tox-tinymce {
  border: 0px !important;
  border-radius: 12px !important;
  font-family: 'Poppins', sans-serif !important;
}

.tox .tox-mbtn__select-label,
.tox .tox-tbtn--bespoke .tox-tbtn__select-label,
.tox .tox-tbtn,
.tox .tox-tbtn svg {
  color: #3c3c3c !important;
}

.tox .tox-tbtn svg {
  fill: #3c3c3c !important;
}

.tox .tox-statusbar__resize-handle,
.tox-statusbar {
  display: none !important;
}

textarea:focus,
input:focus {
  outline: 0;
}

.box-header-top {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

button {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}

.header-info h1 {
  font-size: 24px;
}

.nav-tabs-ct {
  border: 0px !important;
}

.nav-tabs-ct .nav-item.show .nav-link,
.nav-tabs-ct .nav-link.active,
.nav-tabs-ct .nav-link {
  color: #3c3c3c;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  border: 0px;
  padding: 0px;
  margin-right: 15px;
  margin-bottom: 8px;
  height: 42px;
}

.nav-tabs-ct .nav-link.active {
  border-bottom: solid 4px #ffe426;
}

.box-text-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin-bottom: 8px;
}

.box-text-title > .box-ch {
  flex: 1;
  display: inline-flex;
  align-items: center;
}

.box-text-title > .box-ch span {
  font-size: 20px;
  font-weight: 500;
  margin-right: 5px;
}

.white-input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: white;
  color: #3c3c3c;
  border-radius: 48px;
}

.white-input:focus {
  outline: none;
}

.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr input {
  border-right: 0px !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl input {
  border-left: 0px !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  padding-left: 0px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.box-wrap,
.box-wrap-sm {
  position: relative;
  /*max-height: 463px;*/
  /*max-height: 463px;*/
  max-height: 530px;
  overflow: auto;
}

.box-wrap-sm {
  max-height: 350px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box-wrap::-webkit-scrollbar,
.box-wrap-sm::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box-wrap,
.box-wrap-sm {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.box-rectangle {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  padding: 22px 20px 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
}

.box-flex-cols,
.box-flex-div,
.box-list-ui {
  display: flex;
  align-items: center;
}

.box-flex-cols {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.box-flex-cols div:first-child,
.box-flex-div > .col-tabs {
  flex: 1;
}

.box-flex-line {
  margin-top: 10px;
  display: flex;
}

.box-flex-line div:first-child {
  width: 50px;
}

.menu-list {
  width: 235px !important;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 0px;
  padding: 0px !important;
}

.menu-list > li > .dropdown-item {
  font-size: 16px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #3c3c3c;
}

.menu-list > li > .dropdown-item:focus,
.menu-list > li > .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
}

.menu-list > li.bg-yellow {
  background-color: #ffe426;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list > li.bg-light-yellow {
  background-color: rgba(255, 228, 38, 0.4);
}

.bt-check {
  float: right;
}

.box-list {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  border-bottom: solid 1px #eee;
  padding: 0px 0px 8px 0px;
}

.box-list-li {
  display: block;
  margin-top: 10px;
}

.box-list div:first-child {
  width: 80%;
  word-wrap: break-word;
  word-break: break-word;
}

.box-list div:last-child {
  width: 20%;
}

.bg-page-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  color: #3c3c3c;
}

table.table-form thead th:first-child {
  width: 24%;
}

table.table-form tbody td {
  padding: 5px !important;
}

table.table-form tbody td:first-child {
  padding-left: 10px !important;
}

.wd-100 {
  width: 100px;
  margin: 0 auto;
}

.wd-80 {
  width: 80px;
  margin: 0 auto;
}

.wd-175 {
  width: 175px;
}

.box-text-ellipsis {
  overflow: hidden;
  display: block;
  width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box-text-ellipsis:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

/*star rate*/

.box-star-list {
  display: flex;
  align-items: center;
}

.box-line-star {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-right: 15px;
}

.box-line-star span {
  font-size: 16px;
  color: #828282;
}

.rating {
  display: inline-block;
  padding: 0px;
  margin-left: 5px;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position: absolute;
  /*top: -9999px;*/
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  padding: 0 0.1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 22px;
  line-height: 1.2;
  color: #ddd;
  /*text-shadow: 1px 1px #bbb, 2px 2px #666, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating:not(:checked) > label:before {
  content: '★ ';
}

.rating > input:checked ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px #c60, 2px 2px #940, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffe426;
  /*text-shadow: 1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0, 0, 0, .5);*/
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.input-error {
  color: #ee3900 !important;
  border: 1px solid #ee3900 !important;
}

.box-button-bt {
  float: right;
  color: #828282;
  display: inline-flex;
  align-items: center;
}
.box-button-bt-add {
  float: right;
  color: #828282;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}
.bt-submit-disabled {
  width: 137px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
  border: none;
  color: #bdbdbd;
}

.bt-submit {
  width: 137px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  color: #3c3c3c;
}

.header-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #3c3c3c;
  margin-bottom: 15px;
}

.tooltip,
.tooltip > .tooltip-inner {
  background-color: #fff !important;
  font-size: 16px !important;
}

.tooltip {
  width: auto !important;
  padding: 2px;
}

.box-input-list {
  display: inline-flex;
  align-items: center;
  height: 42px;
  width: 100%;
}

.box-checkbox input {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #e0e0e0 !important;
  background-color: #fff;
}

.box-input-list > .box-checkbox {
  flex: 1;
}

.box-checkbox > .form-check-input:checked[type='checkbox'] {
  background-image: url('../public/Images/icon-check.png') !important;
  background-position: center;
  background-size: 16px;
}

.box-checkbox > .form-check-input:checked {
  background-color: #ffe426;
  border-color: #e0e0e0 !important;
}

.form-check-input-disabled {
  background-color: #e0e0e0 !important;
  color: #ffffff !important;
}

.spinner-button,
.box-icon-calendar {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 24px;
  text-align: center;
  margin: 0px;
  pointer-events: none;
  height: 18px;
  font-size: 14px;
}

.box-icon-calendar {
  width: 25px;
  height: 22px;
}

.number-wrapper,
.wrapper-date {
  position: relative;
}

.form-ct {
  height: 42px;
}

.btn {
  box-shadow: none !important;
  margin: 0 !important;
  font-size: 1rem !important;
  color: #3c3c3c;
  font-family: 'Poppins', sans-serif !important;
}

.box-input-text {
  width: 63%;
  margin-right: 10px;
}

.box-info-header {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.box-info-header div {
  padding-right: 20px;
}

.box-info-header div:last-child {
  padding-right: 0px !important;
}

.b-child-1 {
  width: 46%;
}

.b-child-1 > h2 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.4px;
  text-align: left;
  color: #3c3c3c;
  margin: 0px;
}

.b-child-2 {
  width: 54%;
}

.input-cs > .input-group-text,
.input-cs input {
  background: #f5f5f5;
  border: 0px;
}

.input-cs > .input-group-text {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  padding: 0px 15px;
  font-size: 18px;
  color: #828282;
}

.input-cs input {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  padding: 8px;
}

.input-cs > .input-group-text {
  border: 0px;
}

.input-cs input::placeholder {
  color: #828282 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-cs input:focus {
  background: #f5f5f5 !important;
  border: none !important;
  outline: 0;
  box-shadow: unset !important;
}

.form-control {
  border: 1px solid #e0e0e0;
  color: #3c3c3c;
  font-size: 16px !important;
}

.form-control::placeholder {
  color: #bdbdbd;
  font-size: 16px !important;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
}

.btn-circle {
  width: 16px;
  height: 16px;
  margin: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  border: none;
  background-image: url('../public/Images/icon-question.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.dropdown-custom {
  height: 40px !important;
  padding: 8px !important;
  border-radius: 6px !important;
  border: solid 1px #e0e0e0 !important;
  background-color: #fff;
  color: #3c3c3c;
  text-align: left;
}

.dropdown-custom::after {
  display: none;
}

.dropdown-custom span {
  display: block;
  color: #3c3c3c;
  position: absolute;
  right: 10px;
  background-color: white;
  top: 9px;
  padding: 0px 5px;
}

.dropdown-custom:focus {
  box-shadow: none !important;
}

.dropdown-custom:hover {
  color: #3c3c3c;
  background-color: #fff !important;
}

.dropdown-custom.dropdown-toggle.show {
  background-color: #fff !important;
  color: #3c3c3c !important;
}

.dropdown-menu {
  min-width: 100% !important;
  z-index: 3 !important;
}

.dropdown-menu li a {
  font-size: 14px;
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  background: none !important;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

table.table-lists {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  vertical-align: middle;
}

table.table-lists th {
  background-color: #fff;
  border-left: 0px;
  text-align: center;
  color: #828282;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  letter-spacing: -0.02em;
  border-bottom: solid 1px #eee;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-commandes td {
  color: #3c3c3c !important;
}

table.table-commandes thead th:first-child {
  width: 26%;
}

table.table-commandes thead th:nth-child(2) {
  width: 20%;
}

span.bt-circle-wh {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 2.5px;
}

table.table-lists tr.bg-yellow td,
table.table-lists tr:hover td {
  background-color: rgba(255, 228, 38, 0.4) !important;
}

table.table-lists tr.bg-yellow td > span.bt-circle-wh,
span.bt-circle-wh:hover {
  background-color: white;
  display: inline-flex;
  width: 32px;
  height: 32px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

table.table-lists td {
  padding: 0px 15px 0px 15px !important;
  height: 42px;
  vertical-align: middle;
}

.row-page {
  padding: 0px 5px 0px 15px;
}

.box-pagination {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: right;
}

.text-page {
  color: #bdbdbd;
}

.list-page-number {
  margin-left: 20px;
}

.list-page-number span {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.list-page-number span.active {
  background: #ffe426;
}

.bt-plus-circle,
.bt-minus-circle,
.bt-filter-circle,
.bt-download-circle,
.bt-download-circle-selected,
.bt-download-circle-disabled {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bt-download-circle {
  background-image: url('../public/Images/icon-download.png');
}

.bt-download-circle:hover {
  background-image: url('../public/Images/icon-download-hover.png');
}

.bt-download-circle-selected {
  background-image: url('../public/Images/icon-download-hover.png');
}

.bt-download-circle-disabled {
  background-image: url('../public/Images/icon-download.png');
}

.bt-plus-circle {
  background-image: url('../public/Images/icon-plus-default.png');
}

.bt-plus-circle:hover {
  background-image: url('../public/Images/icon-plus-hover.png');
}
.bt-plus-circle:hover {
  background-image: url('../public/Images/icon-plus-hover.png');
}

.bt-minus-circle {
  background-image: url('../public/Images/icon-minus-default.png');
}

.bt-minus-circle:hover {
  background-image: url('../public/Images/icon-minus-hover.png');
}

.wd-66 {
  width: 66px;
  margin: 0 auto;
}

.wd-80 {
  width: 80px;
  margin: 0 auto;
}

.wd-90 {
  width: 80px;
  margin: 0 auto;
}

/**  non attributed orders */

.box-info {
  border: solid 1px transparent;
}

.box-info > .box-sub-ch {
  margin: 27px 0px 11px 0px;
}

.box-info-2 {
  margin-top: 45px;
}

.box-mt {
  margin-top: 50px;
}

.box-collapse img {
  width: 43px;
  cursor: pointer;
}

table.table-attribees thead th:first-child {
  width: 23%;
}

table.table-attribees thead th {
  vertical-align: middle;
}

table.table-attribees tbody td {
  padding: 5px 3px !important;
  height: 50px;
  color: #3c3c3c;
}

.bt-submit-y-v2 {
  height: 52px;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #3c3c3c;
}

.bt-submit-y-disabled-v2 {
  height: 52px;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
  border: none;
  letter-spacing: -0.32px;
  font-size: 16px;
  color: #bdbdbd;
}

.content-center h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0px;
  z-index: 1;
}

table.table-lists td {
  font-weight: 400 !important;
  padding: 0px 15px 0px 15px !important;
  height: 42px;
  vertical-align: middle;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.box-list-header {
  display: inline-flex;
  width: 100%;
  justify-items: center;
  align-items: center;
}

.box-list-header > .box-title {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: -0.02em;
}

.box-input-text {
  width: 63%;
  margin-right: 10px;
}

.input-cs > .input-group-text,
.input-cs input {
  background: #f5f5f5;
  border: 0px;
}

.input-cs > .input-group-text {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  padding: 0px 15px;
  font-size: 18px;
  color: #828282;
}

.input-cs input {
  border-top-left-radius: 47px;
  border-bottom-left-radius: 47px;
  border-top-right-radius: 47px;
  border-bottom-right-radius: 47px;
  padding: 8px;
}

.input-cs > .input-group-text {
  border: 0px;
}

.input-cs input::placeholder {
  color: #828282 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.input-cs input:focus {
  background: #f5f5f5 !important;
  border: none !important;
  outline: 0;
  box-shadow: unset !important;
}

.number-group > .input-group-text {
  padding: 5px 5px 5px 0px;
  font-size: 14px !important;
  color: #bdbdbd !important;
}

.input-gr > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  font-size: 16px;
}

.input-grl > .input-group-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 5px;
  font-size: 16px;
  text-align: center;
}

.input-password > .input-group-text {
  background-color: #fff !important;
  cursor: pointer;
}

.bt-plus-circle,
.bt-filter-circle,
.bt-filter-circle-selected,
.bt-download-circle {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bt-filter-circle {
  background-image: url('../public/Images/icon-filter-default.png');
}
.bt-filter-circle-selected {
  background-image: url('../public/Images/icon-filter-hover.png');
}

.bt-filter-circle:hover {
  background-image: url('../public/Images/icon-filter-hover.png');
}

.bt-plus-circle {
  background-image: url('../public/Images/icon-plus-default.png');
}

.bt-plus-circle:hover {
  background-image: url('../public/Images/icon-plus-hover.png');
}

.box-table {
  max-height: 615px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box-table::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box-table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

table.table-lists {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  vertical-align: middle;
}

table.table-lists th {
  background-color: #fff;
  border-left: 0px;
  text-align: center;
  color: #828282;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 10px;
  letter-spacing: -0.02em;
  border-bottom: solid 1px #eee;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
}

table.table-lists thead th:first-child {
  border-left: 1px solid transparent;
  width: 55%;
  text-align: left;
}

table.table-lists tbody tr td:first-child {
  border-top-left-radius: 12px !important;
  border-left: 1px solid transparent;
  border-bottom-left-radius: 12px !important;
  text-align: left;
  color: #3c3c3c;
}

table.table-lists tbody tr td:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

table.table-lists td {
  background: #f5f5f5;
  border-left: 0px;
  text-align: center;
  color: #000;
  vertical-align: middle;
  font-size: 16px;
}

table.table-lists td i {
  font-weight: bold;
}

table.table-lists tr.bg-yellow td,
table.table-lists tr:hover td {
  background-color: rgba(255, 228, 38, 0.4) !important;
}

table.table-lists tr.bg-yellow td > span.bt-circle-wh,
span.bt-circle-wh:hover {
  background-color: white;
  display: inline-flex;
  width: 32px;
  height: 32px;
  line-height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

span.bt-circle-wh {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: 2.5px;
}

span.bt-circle-wh img {
  height: 26px;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.bt-icon-attr {
  background-image: url('../public/Images/icon-non-attr.png');
}

.bt-icon-en-cour {
  background-image: url('../public/Images/icon-en-cour.png');
}

.bt-icon-en-retard {
  background-image: url('../public/Images/icon-en-retard.png');
}

.bt-icon-valid {
  background-image: url('../public/Images/icon-valid.png');
}

.bt-icon-smart-with-review {
  background-image: url('../public/Images/icon-smart-with-review.png');
}

.bt-icon-smart {
  background-image: url('../public/Images/icon-smart.png');
}

.bt-icon-list {
  position: relative;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  background-size: contain;
}

.bg-orange {
  background-color: #ff8d4b;
}

.bg-gris {
  background-color: #868b8e;
}

.bg-blue {
  background-color: #0064d2;
}

.bg-darkorange {
  background-color: #ee3900;
}

.bg-green {
  background-color: #43b929;
}

.box-circle {
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 40px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
}

.box-header-icon {
  width: 32px;
  height: 38px;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.label-text {
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3c3c3c;
  line-height: 25px;
  margin: 15px 0px;
  font-weight: 500;
}

.box-pd {
  padding: 40px 10px;
  cursor: pointer;
}

.box-pd:hover > .label-text,
.box-pd:hover span i {
  color: #fff;
}

.box-pd:hover span i {
  font-size: 15px !important;
}

.box-pd:hover > .box-header-icon > .bt-icon-list > .icon-sm i {
  font-size: 32px !important;
}

/*box orange*/

.box-pd-orange:hover span {
  background-color: #ffb185 !important;
}

.box-pd-orange:hover {
  background-color: #ffb185;
  color: #fff;
}

.box-pd-gris:hover span {
  background-color: #868b8e !important;
}

.box-pd-gris:hover {
  background-color: #868b8e;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-orange {
  background-color: #fff !important;
  color: #ff8d4b;
}

.box-pd:hover > .box-circle.bg-gris {
  background-color: #fff !important;
  color: #868b8e;
}

/*box blue*/

.box-pd-blue:hover span {
  background-color: #7fb1e8 !important;
}

.box-pd-blue:hover {
  background-color: #7fb1e8;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-blue {
  background-color: #fff !important;
  color: #0064d2;
}

/*box dark orange*/

.box-pd-darkorange:hover span {
  background-color: #f79f83 !important;
}

.box-pd-darkorange:hover {
  background-color: #f79f83;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-darkorange {
  background-color: #fff !important;
  color: #ee3900;
}

/*box green*/

.box-pd-green:hover span {
  background-color: #a1dc94 !important;
}

.box-pd-green:hover {
  background-color: #a1dc94;
  color: #fff;
}

.box-pd:hover > .box-circle.bg-green {
  background-color: #fff !important;
  color: #43b929;
}

.box-pd-blue:hover > .box-header-icon > .bt-icon-en-cour {
  background-image: url('../public/Images/icon-en-cour-white.png');
  transform: scale(1.2);
}

.box-pd-orange:hover > .box-header-icon > .bt-icon-attr {
  background-image: url('../public/Images/icon-none-attr-white.png');
  transform: scale(1.2);
}

.box-pd-gris:hover > .box-header-icon > .bt-icon-attr {
  background-image: url('../public/Images/icon-none-attr-white.png');
  transform: scale(1.2);
}

.box-pd-darkorange:hover > .box-header-icon > .bt-icon-en-retard {
  background-image: url('../public/Images/icon-en-retard-white.png');
  transform: scale(1.2);
}

.box-pd-green:hover > .box-header-icon > .bt-icon-valid {
  background-image: url('../public/Images/icon-valid-white.png');
  transform: scale(1.2);
}

.margin-Slider-filter {
  margin-bottom: 12px;
  margin-right: 50px;
}

.text-field-container {
  display: flex;
  gap: 8px; /* Adjust the value to set the desired margin */
}

.modal-content {
  box-shadow: none !important;
}

.modal-dialog .modal-content {
  box-shadow: none !important;
}

.modal-dialog {
  box-shadow: none !important;
  background-color: #ffffff !important;
  border-radius: 15px;
}

.App-logo-excel {
  height: 30px;
  float: right;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
}

.row-baseline {
  display: flex;
  align-items: baseline !important;
}

.label-title {
  margin-right: 10px;
}

.label-title-filtre {
  margin-right: 20px;
}

.input-container-excel {
  display: flex;
  align-items: center;
  height: 3.5rem;
}

.modal-transparent-background {
  background-color: transparent !important;
}

.modal {
  --bs-modal-width: 80rem !important;
}

.modal-content {
  background-color: transparent !important;
}

.bright-yellow-div {
  background-color: rgb(243, 249, 134);
  width: 100%;
  padding: 8px; /* Adjust the height as per your requirement */
  border-radius: 35px; /* Adjust the border-radius to control the amount of rounding */
}

.bt-filter-circle-non-active {
  background-image: url('../public/Images/icon-filter-default.png');
}
.bt-filter-circle-active {
  background-image: url('../public/Images/icon-filter-hover.png');
}
.vertical-col-filter {
  background-color: rgb(243, 249, 134);
  margin-top: 1.5rem;
  height: 4rem;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 10px;
}

.download-button {
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0px !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0rem !important;
}

.container-excel {
  display: flex;
}

.img-container {
  margin-left: 10px;
}
.MuiAutocomplete-input {
  padding: 1px 4px 7.5px 5px !important;
  min-width: 300px !important;
}
.theme-list-container-filter {
  margin-top: 20px;
  margin-bottom: 30px;
}
.btnClose {
  color: #3c3c3c !important;
  margin-top: 5px;
  float: right;
}

.click-pointer:hover {
  cursor: pointer !important;
}

.confirm {
  width: 114px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 0.5rem !important;
  align-items: center;
  box-shadow: none !important;
  border: none !important;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  color: #fff !important;
  letter-spacing: -0.32px;
  font-size: 16px;
}
.green-text {
  color: green;
}

.red-text {
  color: red;
}
.yellow-text {
  color: #f9b700 !important;
}
.container-table-export {
  display: flex;
}
.domain-container {
  margin-top: 5px;
  margin-left: 5px;
}

.close-modal-btn-upload {
  margin: 5px;
  float: right;
}
.flex-container {
  flex: auto;
  align-items: center;
}
.flex-start {
  flex: auto;
  align-items: start;
}
.bt-plus-circle-yellow {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('../public/Images/icon-plus-hover.png');
}
.bt-csv .bt-plus-circle-yellow {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.ms-2 {
  margin-left: 0.5rem !important;
}

.bt-submit-disabled-y {
  background-color: #e0e0e0;
  color: #bdbdbd;
}
.bt-submit-disabled-y,
.bt-submit-y {
  align-items: center;
  border: none;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  font-size: 16px;
  height: 52px;
  justify-content: center;
  letter-spacing: -0.32px;
  padding: 14px 18px;
}
.float-end {
  float: right !important;
}
.bt-submit-disabled-y {
  box-shadow: 0 0 12px 1px rgba(8, 6, 6, 0.05);
  cursor: not-allowed !important;
}
.button {
  font-family: Poppins, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}
.item-per-table {
  margin-right: 10px;
  width: 300px;
}
.row-homePage {
  display: flex;
}
.mr-8 {
  margin-right: 32px;
}

/* Calendar.css */
.calendar-container-deadline {
  position: relative;
  display: inline-block;
}

.calendar-icon-deadline {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.calendar-icon-deadline img {
  width: 20px; /* Adjust size as needed */
}
.calendar-borderless {
  border: none !important;
}
.form-c-disabled {
  background-color: #e0e0e0 !important;
}

.table-container-campaing-modal {
  height: 300px; /* Adjust the height as needed */
  overflow: auto;
}

.close-modal-btn-upload-within {
  border: tomato;
  float: right !important;
  margin-left: 75rem;
  clear: right;
  margin-bottom: 20px;
}

.row-start {
  align-items: self-start !important;
}
.border-input-c {
  border: 1px solid #e0e0e0 !important;
  margin-left: 5px;
  margin-right: 5px;
  height: 50px;
  border-radius: 5px;
  padding-top: 2px;
}
.border-input-c-disabled {
  border: 1px solid #e0e0e0 !important;
  margin-left: 5px;
  margin-right: 5px;
  height: 50px;
  border-radius: 5px;
  padding-top: 2px;
  background-color: #e0e0e0;
}

.form-control-c {
  height: 50px;
}

.form-control-b {
  border-color: transparent !important;
}
.form-control-b:focus {
  border-color: transparent !important;
}

.bg-img-new-year {
  background-image: url('../public/Images/img_nav/patterns.png'),
    url('../public/Images/img_nav/Nouvel-an.png'),
    url('../public/Images/img_nav/patterns.png') !important;
  background-position: 0% 50%, 50% 50%, 100% 50% !important;
  background-repeat: no-repeat !important;

  background-size: 30% 100%, 40% 100%, 30% 100% !important;
}

.bg-img-new-year-admin {
  background-image: url('../public/Images/img_nav/Nouvel-an.png'),
    url('../public/Images/img_nav/patterns.png') !important;
  background-position: 0% 50%, 100% 50% !important; /* Position images */
  background-repeat: no-repeat !important;
  background-size: 50% 100%, 50% 100% !important; /* Size of each image */
}

.bg-img-new-year-1 {
  background-image: url('../public/Images/img_nav/patterns.png') !important;

  background-size: cover !important;

  /* background-repeat: repeat-x; */
}

.centered-under-construction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.content-under-construction {
  text-align: center;
}

.modal-backdrop.show {
  scale: 1.7;
}
